<template>
  <nuxt-link to="/theme/paris" class="web-logo nav-logo">
    <!-- <div v-if="headerLogo"> -->
      <img src="/images/point_banner1.png" class="img-fluid" alt="" style="width: 150px">
    <!-- </div> -->

  </nuxt-link>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSiteStore } from "~~/store/site";

let props = defineProps({
  themeOptions: Object,
  textClass: { type: String, default: 'text-white f-w-600' },
  logo: String
})
let headerLogo = ref('')
let route = useRoute();
let router = useRouter();
let { SiteSettings } = storeToRefs(useSiteStore());

// TODO: Remove set logo
// function setLogo() {
//   if (route.path === '/theme/paris' || route.path === '/theme/osaka') {
//     headerLogo.value = '/images/logo/1.png';
//   } else if (route.path === '/theme/tokyo') {
//     headerLogo.value = '/images/logo/2.png';
//   } else if (route.path === '/theme/rome') {
//     headerLogo.value = '/images/logo/3.png';
//   } else if (route.path === '/theme/madrid') {
//     headerLogo.value = '/images/logo/4.png';
//   } else if (route.path === '/theme/berlin' || route.path === '/theme/denver') {
//     headerLogo.value = '/images/logo/6.png';
//   } else {
//     headerLogo.value = props?.themeOptions?.logo?.header_logo?.original_url;
//   }
// }

onMounted(() => {
  // setLogo();
  // router.afterEach(() => {
  //   setLogo();
  // });
});
</script>

<style lang="scss" scoped></style>